const logotext = "RONAK BHATT";
const meta = {
    title: "Ronak Bhatt | Singer | Composer | Performer | Guitarist",
    description: "Ronak Bhatt is an Indian singer, songwriter, and composer. He was born and brought up in Ahmedabad, Gujarat, India.",
};

const introdata = {
    title: "I’m Ronak Bhatt",
    animated: {
        first: "An Indian singer",
        second: "songwriter, and composer",
        third: "I recoded cool songs!",
    },
    description: "I’m Ronak Bhatt an Indian singer, songwriter, and composer. He was born and brought up in Ahmedabad, Gujarat, India.",
    your_img_url: "https://iili.io/HxjfkOl.jpg",
};

const dataabout = {
    title: "A bit about my self",
    aboutme_1: "Ronak Bhatt (born 2 Feb 1994) is an Indian singer and composer. He was born in Ahmedabad, Gujarat, India. Ronak Bhatt is an Indian singer, songwriter, and composer. He was born and brought up in Ahmedabad, Gujarat, India. He sings predominantly in Hindi and Gujarati languages. He is a big fan of singer Arijit Singh, who is also an inspiration for his looks. Ronak Bhatt has performed at many stages in Sabarmati Festival, Gtpl Unplugged, and etc. GTPL unplugged was his first reality show, which made him popular. Ronak Bhatt is fluent in Hindi, English, and Gujarati languages.",
    aboutme_2: "So far he has composed about 15 songs. His first song Tujhse Naraz Nahi Zindagi was released on all music platforms including Apple Music, Spotify, Tidal, Youtube music. song Going by the more than 5000 Views it has garnered on YouTube, people can’t stop listening to this song."
};

const albums = [
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/6g3jUfnqpaJ14xgb8zS9WT"
    },
    "href": "https://api.spotify.com/v1/albums/6g3jUfnqpaJ14xgb8zS9WT",
    "id": "6g3jUfnqpaJ14xgb8zS9WT",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b27315e5076ff2b6f19087e74862",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e0215e5076ff2b6f19087e74862",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d0000485115e5076ff2b6f19087e74862",
        "width": 64
      }
    ],
    "name": "Shiva Rudrashtakam",
    "release_date": "2023-02-15",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:6g3jUfnqpaJ14xgb8zS9WT"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/1YMKNchguWUWQ1NBB2KK9P"
    },
    "href": "https://api.spotify.com/v1/albums/1YMKNchguWUWQ1NBB2KK9P",
    "id": "1YMKNchguWUWQ1NBB2KK9P",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2732b38c7d56955389ff781f13e",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e022b38c7d56955389ff781f13e",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048512b38c7d56955389ff781f13e",
        "width": 64
      }
    ],
    "name": "Get Relaxed",
    "release_date": "2023-02-07",
    "release_date_precision": "day",
    "total_tracks": 2,
    "type": "album",
    "uri": "spotify:album:1YMKNchguWUWQ1NBB2KK9P"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/0uQV2ApUpzrXtiXPsdVGdB"
    },
    "href": "https://api.spotify.com/v1/albums/0uQV2ApUpzrXtiXPsdVGdB",
    "id": "0uQV2ApUpzrXtiXPsdVGdB",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2734128ee44a90f0d126fdeddbb",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e024128ee44a90f0d126fdeddbb",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048514128ee44a90f0d126fdeddbb",
        "width": 64
      }
    ],
    "name": "Rahe Na Rahe",
    "release_date": "2022-12-26",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:0uQV2ApUpzrXtiXPsdVGdB"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/1tB2xp0pdA1b7kpnXyJ50W"
    },
    "href": "https://api.spotify.com/v1/albums/1tB2xp0pdA1b7kpnXyJ50W",
    "id": "1tB2xp0pdA1b7kpnXyJ50W",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2738ee76f7fbc2c813377e0680c",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e028ee76f7fbc2c813377e0680c",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048518ee76f7fbc2c813377e0680c",
        "width": 64
      }
    ],
    "name": "Hum Tum",
    "release_date": "2022-12-22",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:1tB2xp0pdA1b7kpnXyJ50W"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/6uGRh7PxQq6JUM5N2rMK0K"
    },
    "href": "https://api.spotify.com/v1/albums/6uGRh7PxQq6JUM5N2rMK0K",
    "id": "6uGRh7PxQq6JUM5N2rMK0K",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2731f1910dbaa67a72ebe66e4a2",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e021f1910dbaa67a72ebe66e4a2",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048511f1910dbaa67a72ebe66e4a2",
        "width": 64
      }
    ],
    "name": "Ghar Se Nikalte Hi",
    "release_date": "2022-10-24",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:6uGRh7PxQq6JUM5N2rMK0K"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/1lzY5Ho4WD8GHVC7ckuwxR"
    },
    "href": "https://api.spotify.com/v1/albums/1lzY5Ho4WD8GHVC7ckuwxR",
    "id": "1lzY5Ho4WD8GHVC7ckuwxR",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b273acddd27e01d9c195e0143a1d",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e02acddd27e01d9c195e0143a1d",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d00004851acddd27e01d9c195e0143a1d",
        "width": 64
      }
    ],
    "name": "Tujhe Yaad Na Meri Aayi",
    "release_date": "2022-10-24",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:1lzY5Ho4WD8GHVC7ckuwxR"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/0XPjDQoY5rAdOvtbA5Bqtn"
    },
    "href": "https://api.spotify.com/v1/albums/0XPjDQoY5rAdOvtbA5Bqtn",
    "id": "0XPjDQoY5rAdOvtbA5Bqtn",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b273df22610be8ace7e459ac16c5",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e02df22610be8ace7e459ac16c5",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d00004851df22610be8ace7e459ac16c5",
        "width": 64
      }
    ],
    "name": "Tum Ko Dekha To",
    "release_date": "2022-10-03",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:0XPjDQoY5rAdOvtbA5Bqtn"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/01lkSUriK3X92xA4SFnNUB"
    },
    "href": "https://api.spotify.com/v1/albums/01lkSUriK3X92xA4SFnNUB",
    "id": "01lkSUriK3X92xA4SFnNUB",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2738a20d10f424931da2a1f0a22",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e028a20d10f424931da2a1f0a22",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048518a20d10f424931da2a1f0a22",
        "width": 64
      }
    ],
    "name": "Hairani Hoti Hai",
    "release_date": "2022-08-26",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:01lkSUriK3X92xA4SFnNUB"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/0qrqTCmFOtYOspQMReJ7hk"
    },
    "href": "https://api.spotify.com/v1/albums/0qrqTCmFOtYOspQMReJ7hk",
    "id": "0qrqTCmFOtYOspQMReJ7hk",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b27332e8db1675b9535c94dad04f",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e0232e8db1675b9535c94dad04f",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d0000485132e8db1675b9535c94dad04f",
        "width": 64
      }
    ],
    "name": "Satrangi Che Re Maro Vhalam",
    "release_date": "2022-07-09",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:0qrqTCmFOtYOspQMReJ7hk"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/56ZfHYUPqmHAOQvspTyPRI"
    },
    "href": "https://api.spotify.com/v1/albums/56ZfHYUPqmHAOQvspTyPRI",
    "id": "56ZfHYUPqmHAOQvspTyPRI",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b27387aa2052dd1f085cc53c4834",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e0287aa2052dd1f085cc53c4834",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d0000485187aa2052dd1f085cc53c4834",
        "width": 64
      }
    ],
    "name": "Shree Ram Chandra Kripalu Bhajman",
    "release_date": "2022-06-05",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:56ZfHYUPqmHAOQvspTyPRI"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/6tACZl9c3ea5suGeF501dJ"
    },
    "href": "https://api.spotify.com/v1/albums/6tACZl9c3ea5suGeF501dJ",
    "id": "6tACZl9c3ea5suGeF501dJ",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2735c05fab925fae3a1f74bba9d",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e025c05fab925fae3a1f74bba9d",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048515c05fab925fae3a1f74bba9d",
        "width": 64
      }
    ],
    "name": "Kehta Hai Dil Mera",
    "release_date": "2022-05-06",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:6tACZl9c3ea5suGeF501dJ"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/5F4HYGlLgSSIbn0yJbylAU"
    },
    "href": "https://api.spotify.com/v1/albums/5F4HYGlLgSSIbn0yJbylAU",
    "id": "5F4HYGlLgSSIbn0yJbylAU",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b273288ca7ae96ac7227a5caddf6",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e02288ca7ae96ac7227a5caddf6",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d00004851288ca7ae96ac7227a5caddf6",
        "width": 64
      }
    ],
    "name": "Udaarian",
    "release_date": "2022-03-12",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:5F4HYGlLgSSIbn0yJbylAU"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/7vhrxEMxAMQhds9SVZ1Wmn"
    },
    "href": "https://api.spotify.com/v1/albums/7vhrxEMxAMQhds9SVZ1Wmn",
    "id": "7vhrxEMxAMQhds9SVZ1Wmn",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2733894dee464090b4892beebe8",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e023894dee464090b4892beebe8",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048513894dee464090b4892beebe8",
        "width": 64
      }
    ],
    "name": "Shiv Shiv Shambhu",
    "release_date": "2022-02-28",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:7vhrxEMxAMQhds9SVZ1Wmn"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/2gkBRslNH6trXYMs5Axx3S"
    },
    "href": "https://api.spotify.com/v1/albums/2gkBRslNH6trXYMs5Axx3S",
    "id": "2gkBRslNH6trXYMs5Axx3S",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2733bf7b1268d7345ed96380e5f",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e023bf7b1268d7345ed96380e5f",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048513bf7b1268d7345ed96380e5f",
        "width": 64
      }
    ],
    "name": "Chan Kithan (Short Version)",
    "release_date": "2022-02-04",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:2gkBRslNH6trXYMs5Axx3S"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/32dYqb3JGbKszW5cGe7Gkd"
    },
    "href": "https://api.spotify.com/v1/albums/32dYqb3JGbKszW5cGe7Gkd",
    "id": "32dYqb3JGbKszW5cGe7Gkd",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b27350839ff90e7279035ae32a33",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e0250839ff90e7279035ae32a33",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d0000485150839ff90e7279035ae32a33",
        "width": 64
      }
    ],
    "name": "Ochintu Koi Mane",
    "release_date": "2022-02-04",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:32dYqb3JGbKszW5cGe7Gkd"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/7mzJklcB9wTCF4f8tBs8Ll"
    },
    "href": "https://api.spotify.com/v1/albums/7mzJklcB9wTCF4f8tBs8Ll",
    "id": "7mzJklcB9wTCF4f8tBs8Ll",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b2732211214139d0234428daa4b6",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e022211214139d0234428daa4b6",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d000048512211214139d0234428daa4b6",
        "width": 64
      }
    ],
    "name": "Do Gallan",
    "release_date": "2022-02-03",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:7mzJklcB9wTCF4f8tBs8Ll"
  },
  {
    "album_group": "single",
    "album_type": "single",
    "artists": [
      {
        "external_urls": {
          "spotify": "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT"
        },
        "href": "https://api.spotify.com/v1/artists/57OlkDwFEiWMKvrmlQPVOT",
        "id": "57OlkDwFEiWMKvrmlQPVOT",
        "name": "Ronak Bhatt",
        "type": "artist",
        "uri": "spotify:artist:57OlkDwFEiWMKvrmlQPVOT"
      }
    ],
    "external_urls": {
      "spotify": "https://open.spotify.com/album/4fMhfx8qUNxpVGyHYvTvH9"
    },
    "href": "https://api.spotify.com/v1/albums/4fMhfx8qUNxpVGyHYvTvH9",
    "id": "4fMhfx8qUNxpVGyHYvTvH9",
    "images": [
      {
        "height": 640,
        "url": "https://i.scdn.co/image/ab67616d0000b27365fabde261a5372ca0c51af6",
        "width": 640
      },
      {
        "height": 300,
        "url": "https://i.scdn.co/image/ab67616d00001e0265fabde261a5372ca0c51af6",
        "width": 300
      },
      {
        "height": 64,
        "url": "https://i.scdn.co/image/ab67616d0000485165fabde261a5372ca0c51af6",
        "width": 64
      }
    ],
    "name": "Teri Hogaiyaan",
    "release_date": "2022-01-31",
    "release_date_precision": "day",
    "total_tracks": 1,
    "type": "album",
    "uri": "spotify:album:4fMhfx8qUNxpVGyHYvTvH9"
  }
];

const contactConfig = {
    YOUR_EMAIL: "hello@ronakbhatt.in",
    YOUR_FONE: "+91 96011 95650",
    description: "Let's get connected!!",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_gl032l3",
    YOUR_TEMPLATE_ID: "template_9l190ur",
    YOUR_USER_ID: "ObXHx3JqALTroBAPW",
};

const socialprofils = {
    facebook: "https://www.facebook.com/RonakbhattRz",
    twitter: "https://twitter.com/ronakabhattrz",
    youtube: "https://www.youtube.com/channel/UC_jKbr7ACXE7LsuDoLaCJcw",
    spotify: "https://open.spotify.com/artist/57OlkDwFEiWMKvrmlQPVOT",
    instagram: "https://www.instagram.com/ronakbhattrz"
};
export {
    meta,
    dataabout,
    albums,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};